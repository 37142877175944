.header {
	display: flex;
	align-items: center;
	margin: 0;
	justify-content: space-between;

	&_title {
		margin: 0;
		font-size: 1.25rem; } }

.body_empty {
	margin: 5rem 0;
	font-weight: 400;
	color: var(--secondary-text); }
