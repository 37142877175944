.dialog {
	top: 0;
	bottom: 0;
	z-index: 50;
	border: none;
	margin: auto;
	display: flex;
	padding: 2.5rem;
	position: fixed;
	min-width: 50vw;
	min-height: 60vh;
	max-height: 95vh;
	border-radius: 5px;
	flex-direction: column;

	&_header {
		display: inline-flex;
		align-items: flex-start;
		justify-content: space-between;

		&_title {
			margin: 0;
			font-weight: 600;
			font-size: 1.5rem;
			line-height: 1.75rem; }

		&_close {
			transition: all 500ms ease-in-out;

			&:hover {
				color: var(--link); } } }

	&_sources {
		flex-grow: 2;
		overflow-y: auto;

		&_header {
			display: flex;
			flex-wrap: wrap;
			column-gap: .5rem;
			align-items: baseline;

			fieldset {
				margin: 0;
				width: 100%;
				border: none;
				display: flex;
				padding: .75rem 0;

				input {
					border: none;
					border-bottom: 1px solid var(--border); } }

			&_title {
				margin: .5rem 0;
				font-size: 1rem;
				text-align: left;
				color: var(--secondary-text); }

			&_link {
				cursor: pointer;
				font-size: 1rem;
				color: var(--link); } }

		&_container {
			gap: 1rem;
			margin: 0;
			display: grid;
			padding: 0 .25rem;
			column-gap: 0.5rem;
			grid-template-columns: repeat(4, calc((100% - 1.5rem) / 4));

			&_card {
				margin: 0;
				width: 100%;
				display: flex;
				padding: .5rem;
				cursor: pointer;
				column-gap: .25rem;
				align-items: center;
				border-radius: .25rem;
				background-color: var(--card-bg);
				box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22);

				&_img {
					width: 1.8rem; }

				&_name {
					width: 100%;
					font-size: 1rem;
					overflow: hidden;
					text-align: left;
					white-space: nowrap;
					box-sizing: border-box;
					text-overflow: ellipsis; }

				&_check {
					margin: 0;
					padding: 0;
					width: 2.5rem; } } } }

	&_footer {
		display: inline-flex;
		justify-content: flex-start;

		&_btn {
			border: none;
			cursor: pointer;
			font-size: 1rem;
			color: #ffffff;
			line-height: 18px;
			text-align: center;
			padding: .8rem 1.5rem;
			border-radius: .25rem;
			font-family: var(--main-font);
			background-color: var(--link);

			&:disabled {
				cursor: not-allowed;
				background-color: gray; } } } }
