.wcc-countdown {
  .wcc-warning {
    

    .wcc-wicon {
      margin-right: 20px;
      i,
      path {
        color: #e31e28;
      }
      .wcc-iclock {
        font-size: 35px;
      }
      .wcc-iclock-warning {
        font-size: 19px;
        margin-left: -11px;
        position: relative;
        bottom: -4px;
      }
    }
  }

  .wcc-counter {
    display: flex;
    height: 64px;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    align-items: center;
    margin-bottom: 30px;
    * {
      color: var(--main-text);
    }
    .wcc-tleft {
      i {
        margin-right: 5px;
      }
    }

    .wcc-tright {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
      align-items: center;

      .wcc-pad {
        margin-right: 10px;
        font-size: 24px;
      }

      button {
        background: none;
        border: none;
        font-size: 40px;
        cursor: pointer;

        .fa-play-circle {
          color: #f69221;
        }
        .fa-stop-circle {
          color: #e31e28;
        }
      }
    }
  }
}
