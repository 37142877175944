.skeleton {
	display: grid;
	padding: .5rem;
	column-gap: 1rem;
	position: relative;
	align-items: center;
	justify-items: center;
	transition: all 500ms ease-in-out;
	border-bottom: 1px solid var(--border);
	grid-template-columns: 1.5rem repeat(2, 1fr) .5fr 1.5rem;

	&_figure {
		margin: 0;
		width: 100%;
		display: inline-grid;
		row-gap: .5rem;
		grid-template-columns: 2.5rem 1fr;
		grid-template-rows: repeat(2, 1rem);

		&_img {
			width: 100%;
			height: 100%;
			grid-row: 1/ 3;
			grid-column: 1/ 2;
			border-radius: 50%;
			fill: var(--skeleton-bg); }

		&_item {
			width: 70%;
			height: .8rem;
			align-self: end;
			background-color: var(--skeleton-bg);

			&:last-child {
				width: 40%;
				height: .5rem;
				align-self: start; } } }

	&_item {
		width: 100%;
		height: .8rem;
		background-color: var(--skeleton-bg); }

	&_icon {
		margin: 0;
		padding: 0;
		width: 100%;

		svg {
			font-size: 1.5rem;
			fill: var(--skeleton-bg); }

		&:last-child {
			svg {
				font-size: 1.25rem; } } } }
