.main {
	padding: 0 1rem;
	&_header {
		display: flex;
		margin: 0;
		align-items: center;
		justify-content: space-between;
		&_title {
			margin: 0;
			font-size: 1.25rem; } }
	&_footer {
		display: flex;
		margin-top: 1rem;
		align-items: flex-end;
		justify-content: space-between; } }
