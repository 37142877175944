$maxWithd: 473px;

.wcc-groups {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 473px;

  * {
    color: var(--main-text);
  }

  .wcc-selected,
  .wcc-groupresult {
    flex: 0 0 95%;
    max-width: 473px;
    align-self: flex-end;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  li {
    height: 56px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 10px 20px;
    border-bottom: solid 1px var(--border);

    .wcc-gimg {
      width: 36px;
      height: 36px;
      display: flex;
      vertical-align: middle;
      margin-right: 15px;
      box-shadow: 0px 1px 4px 0px var(--shadow);
      overflow: hidden;
      border-radius: 36px;
      font-size: 30px;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    .wcc-gname {
      flex: 1 1;
      display: flex;
      align-items: center;
    }

    .wcc-gremove {
      border: none;
      background: none;
      flex: 0 0 30px;
      width: 30px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .wcc-selected {
    width: 95%;
    margin-top: 20px;

    li {
      padding: 0;
    }
  }

  .wcc-gbutton {
    display: flex;
    justify-content: center;
    align-items: center;

    .wcc-gicon {
      margin-left: -30px;
      font-size: 30px;
      width: 30px;
      flex: 1 1 30px;
    }

    button {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      background: none;
      font-weight: 400;
      border: none;
      border-bottom: solid 1px var(--border);
      flex: 0 0 95%;
      max-width: 473px;
      font-size: 22px;
    }
  }
  .wcc-noresult {
    margin: 20px;
    text-align: center;
  }
  .wcc-gresult {
    width: 95%;
    position: absolute;
    top: 45px;
    right: 0;
    border-radius: 6px;
    background: var(--card-bg);
    box-shadow: 0px 10px 17px 1px rgba(3, 3, 3, 0.14);
    max-height: 400px;
    overflow-y: auto;

    li:hover {
      background: var(--main-bg);
    }

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .wcc-search {
      width: 100%;
      height: 60px;
      border: none;
      padding: 0 20px;
      font-size: 16px;
      border-bottom: solid 2px var(--border);
    }
  }
}
