.wcc-assigned {
  display: flex;
  flex-direction: column;
  position: relative;

  .wcc-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .wcc-showAssigner {
      position: relative;
    }
  }
  .wcc-assignedlist {
    & > div {
      display: flex;
      margin-bottom: 10px;
    }
    .wcc-asimage {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0px 1px 4px 0px var(--shadow);
      background-color: var(--card-bg);
      margin-right: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;

      path{
        color: var(--secondary-btn-bg);
      }

      img {
        max-width: 100%;
      }
    }
  }

  .wcc-modal {
    position: absolute;
    background: var(--card-bg);
    z-index: 1;
    width: 300px;
    left: 0;
    top: 28px;
    min-height: 105px;

    .wcc-tabs {
      display: flex;
      justify-content: stretch;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.23);

      button {
        flex: 1 1 50%;
        border: none;
        background: var(--card-bg);
        height: 42px;
        cursor: pointer;

        &.wcc-active {
          border-bottom: solid 2px var(--naranja-btn);
        }
      }
    }

    .wcc-groups {
      .wcc-gresult {
        top: 4px;
        width: 100%;
      }
    }
  }
}
