.item {
	padding: .5rem 0;

	&__open {
		border-radius: 0.25rem;
		background: var(--main-bg);
		border: 1px solid var(--border); }

	&_header {
		display: grid;
		padding: .5rem 0;
		column-gap: .25rem;
		grid-template-columns: 1.6rem 1fr 1.6rem 1.6rem;

		&_name {
			text-align: left;
			font-weight: bolder; }

		&_ic {
			padding: 0 !important;

			svg {
				transition: all 500ms ease-in-out; }

			&:hover {
				background-color: transparent !important; }

			&__outline {
				&:hover {
					svg {
						fill: var(--link); } } }

			&__solid {
				&:hover {
					svg {
						stroke: var(--link); } } } } }

	&_categories {
		padding-bottom: 1rem;

		&_title {
			margin: .5rem 0;
			font-size: 1rem;
			text-align: left;
			color: var(--secondary-text); }

		&_sources {
			gap: 1rem;
			margin: 0;
			display: grid;
			grid-template-columns: repeat(5, calc((100% - 4rem) /5 ));

			&_card {
				margin: 0;
				width: 100%;
				display: flex;
				padding: .5rem;
				column-gap: .25rem;
				border-radius: .25rem;
				background-color: var(--card-bg);
				box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);

				&_img {
					width: 1.8rem; }

				&_name {
					width: 100%;
					font-size: 1rem;
					overflow: hidden;
					text-align: left;
					white-space: nowrap;
					box-sizing: border-box;
					text-overflow: ellipsis; } } } } }
