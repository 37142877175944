.item {
	display: grid;
	padding: .5rem 0;
	font-size: .9rem;
	position: relative;
	align-items: center;
	transition: all 500ms ease-in-out;
	border-top: 1px solid var(--border);
	grid-template-columns: 1fr .5fr 1fr .75fr 4rem;

	&:last-child {
		border-bottom: 1px solid var(--border); }

	&_detail {
		text-align: center; }

	&_actions {
		display: inline-grid;
		grid-auto-flow: column;
		justify-content: space-evenly;

		&_ic {
			padding: 0 !important;

			svg {
				transition: all 500ms ease-in-out; }

			&:hover {
				background-color: transparent !important; }

			&__outline {
				&:hover {
					svg {
						fill: var(--link); } } }

			&__solid {
				&:hover {
					svg {
						stroke: var(--link); } } } } } }
