.item {
	border-bottom: 1px solid var(--border);
	padding: .5rem;

	&__open {
		background: var(--main-bg);
		border-radius: 0.25rem;
		border: 1px solid var(--border);
		box-shadow: 0px 1px 4px 0px var(--shadow); }

	&_header {
		display: grid;
		grid-template-columns: 4rem  2fr 1fr 1fr 4rem;
		&_icons {
			padding: 0 !important;
			color: var(--secondary-text);
			&:hover {
				background-color: transparent !important; } }
		&_name {
			display: inline-flex;
			align-items: center;
			font-weight: normal;
			column-gap: .25rem;
			&_img {
				width: 2rem; } }
		&_status {
			font-size: 1rem;
			color: var(--success);
			font-weight: bold;
			&__suspended {
				color: var(--error); } }
		&_members {
			display: flex;
			font-size: 1rem;
			color: var(--link);
			column-gap: 0.25rem;
			align-items: center;
			&_iconMember {
				padding: 0 !important;
				& svg {
					fill:  var(--link) !important; }
				&:hover {
					background-color: transparent !important; } } } }



	&_details {
		display: flex;
		padding: 1.6rem 4.6rem;
		flex-direction: column;
		&_toggle {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
			&_switch {
				display: inline-flex;
				align-items: center;
				margin: 1rem;
				column-gap: .25rem; } } } }
