// Pagination
.wcc-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  path,
  button {
    color: var(--main-text);
  }
  .Mui-selected {
    background-color: var(--naranja-btn) !important;
    color: #fff !important;
  }
}
