.item {
	border-bottom: 1px solid var(--border);
	padding: .5rem;

	&__open {
		background: var(--main-bg);
		border-radius: 0.25rem;
		border: 1px solid var(--border);
		box-shadow: 0 1px 4px 0 var(--shadow); }

	&_header {
		display: grid;
		column-gap: 1rem;
		grid-template-columns: 1.6rem repeat(2, 2fr) 1fr 1.6rem;
		&_icons {
			padding: 0 !important;
			&:hover {
				background-color: transparent !important; } }
		&_name {
			margin: 0;
			column-gap: .75rem;
			font-weight: normal;
			align-items: center;
			display: inline-flex;
			&_img {
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%; }
			&_data {
				display: grid;
				text-align: left;
				&_role {
					font-size: .7rem;
					color: var(--step-menu); } } }
		&_email {
			margin: 0;
			font-size: 1rem;
			color: var(--secondary-text); }
		&_status {
			margin: 0;
			font-size: 1rem;
			font-weight: bold;
			color: var(--success);
			&__suspended {
				color: var(--error); } } }

	&_details {
		display: flex;
		padding: 1.6rem 4.6rem;
		flex-direction: column;
		&_toggle {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
			&_switch {
				display: inline-flex;
				align-items: center;
				margin: 1rem;
				column-gap: .25rem; } }
		&_orgs {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
			&_card {
				display: inline-flex;
				align-items: center;
				flex: 1 1 40%;
				border: 1px solid var(--border);
				border-radius: 0.25rem;
				background-color: var(--card-bg);
				padding: 0.5rem .75rem;
				margin: 0;
				column-gap: 0.5rem;
				&_img {
					width: 3rem; } } } } }




