.light {
	--main-text: #272727;
	--secondary-text: #272727B2;
	--link: #f69221;
	--lmslink: #1288d6;
	--error: red;
	--success: #76caa0;
	--main-bg: #f4f6f9;
	--card-bg: white;
	--naranja-btn: #f19126;
	--main-font: 'proxima-nova', sans-serif;
	--logo: url('../images/torch-logo.png');
	--border: rgba(0, 0, 0, 0.15);
	--shadow: rgba(0, 0, 0, 0.1);
	--secondary-btn-bg: #272727;
	--secondary-btn-text: white;
	--step-menu: rgba(77, 77, 77, 0.7);
	--warning: rgba(255, 152, 0, 0.2);
	--multi-options: white;
	--skeleton-bg: #e6e6e6;
}

.dark {
	--main-text: white;
	--secondary-text: rgba(255, 255, 255, 0.7);
	--link: #f69221;
	--error: red;
	--success: #76caa0;
	--main-bg: #272727;
	--card-bg: #313131;
	--naranja-btn: #f19126;
	--main-font: 'proxima-nova', sans-serif;
	--logo: url('../images/torch-logo-white.png');
	--border: rgba(255, 255, 255, 0.1);
	--shadow: rgba(255, 255, 255, 0.05);
	--secondary-btn-bg: white;
	--secondary-btn-text: #272727;
	--step-menu: rgba(77, 77, 77, 0.7);
	--warning: rgba(255, 152, 0, 0.2);
	--multi-options: white;
	--skeleton-bg: #c8c8c8;
}
