.container {
	width: 100%;
	&_header {
		display: grid;
		grid-template-columns: 4rem  2fr 1fr 1fr 4rem;
		&_span {
			font-size: 1rem;
			padding: .25rem 0;
			color: var(--secondary-text); } }

	&_footer {
		display: flex;
		margin-top: 1rem;
		align-items: flex-end;
		justify-content: space-between; } }
