.skeleton {
	display: grid;
	padding: .8rem 0;
	font-size: .9rem;
	justify-items: center;
	transition: all 500ms ease-in-out;
	border-top: 1px solid var(--border);
	grid-template-columns: 1fr .5fr 1fr .75fr 1.6rem 1.6rem;

	&_item {
		width: 50%;
		height: .8rem;
		overflow: hidden;
		position: relative;
		background-color: var(--skeleton-bg); } }
